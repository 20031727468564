import React from "react";
import { Container, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLevelUpAlt } from '@fortawesome/free-solid-svg-icons'

const LoginBanner = () => {
    return (
        <Container>
            <Alert variant="info">
                <h1 className="login-alert">Login here <FontAwesomeIcon icon={faLevelUpAlt} /></h1>
            </Alert>
        </Container>
    )
}

export default LoginBanner;