import React from 'react';

class ErrorMessage extends React.Component {
    render() {
        return (
            <div>
                <span className="error-message">{this.props.message}</span>
            </div>
        );
    }
}

export default ErrorMessage;