import React, { useEffect, useState } from 'react';
import { Table, Container, Jumbotron, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { archiveForms, getAllForms } from '../services/formServices';
import { useAuth0 } from "@auth0/auth0-react";
import { NavBar } from '../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';


const ApplicationsList = () => {

    const [forms, setForms] = useState([]);
    const { getAccessTokenSilently } = useAuth0();

    const sendFormsToArchive = () => {
        getAccessTokenSilently().then(token => archiveForms(token)).then((success) => {
            console.log(success)
        }).catch((message) => {
            console.log("something bad happened somewhere. Perhaps a unicorn was killed.")
        });

        getAccessTokenSilently().then(token => getAllForms(token)).then(result => {
            setForms(result.forms);
        })
    }

    useEffect(() => {
        getAccessTokenSilently().then(token => getAllForms(token)).then(result => {
            setForms(result.forms);
        })

    }, [getAccessTokenSilently])

    return (
        <>
            <NavBar navContent="Pályázatok"
                navBack="/management">
            </NavBar>
            <Container>
                <Row>
                    <Col md={{ span: 8, offset: 2 }}>
                        <Jumbotron>
                            <Row>
                                <Button onClick={sendFormsToArchive} className="align-left" title="Az egy hónapnál régebbi jelentkezések archiválása.">Archiválás</Button>
                            </Row>
                            <Row>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Title</th>
                                            <th>Date</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {forms.length >= 1 && (forms.map((form, i) =>
                                            <tr key={i}>
                                                <td>{form.name}</td>
                                                <td>{form.title}</td>
                                                <td>{form.date}</td>
                                                <td><Link to={`form?id=${form.file}`}><FontAwesomeIcon icon={faFile} /></Link></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Row>
                        </Jumbotron>
                    </Col>
                </Row>
            </Container>
        </>
    );

}

export default ApplicationsList;