import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";

const RequiredMark = () => {
    return (
        <FontAwesomeIcon icon={faAsterisk} className="required-mark" title="This field is required." ></FontAwesomeIcon>
    );

};

export default RequiredMark;