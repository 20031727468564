export async function postForm(data, token) {
    const response = await fetch('/api/form', {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ form: data, token })
    });

    return await response.json();
}

export async function getAllForms(token,isArchived) {

    const response = await fetch(`/api/forms?isArchived=${isArchived}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    });
    return await response.json();
}

export async function getForm(formId, isArchived, token) {
    const response = await fetch(`/api/form?id=${formId}&isArchived=${isArchived}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    });
    return await response.json();
}

export async function uploadImage(img) {
    const data = new FormData();
    data.append("file", img);

    const response = await fetch(`/api/upload-image`, {
        method: 'POST',
        body: data
    });

    return await response.json();
}

export async function loadImage(imageId) {
    const response = await fetch(`/api/image?id=${imageId}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    });
    return await response.json();
}

export async function archiveForms(token){
    const response = await fetch("/api/archive", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    });

    return await response.json();
}