import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";


const InfoText = (props) => {
    return(
        <p className="info-text">
            <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>{props.content}
        </p>
    )
}

export default InfoText;