import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Container, Row, Col, Jumbotron, Form, Button } from "react-bootstrap";
import { RecaptchaPublicKey } from "./constants";
import { rowLimits, validityRequirements as valReqs, maxImageSize } from "./validityConstants";
import { RequestedObservingTime, Fig, PreviouslyAwardedTime, Target, ErrorList } from "./formComponents";
import { textValidityCheck } from "./validationHelper";
import { ErrorMessage, SubmitMessage } from "./form";
import { postForm, uploadImage } from "../services/formServices";
import RequiredMark from "./requiredMark";
import InfoText from "./InfoText";

const recaptchaRef = React.createRef();

class ApplicationFormContent extends React.Component {
    reader = new FileReader();

    state = {
        applicantName: "",
        applicantEmail: "",
        applicantAffiliation: "",
        title: "",
        abstract: "",
        requestedObservingTimes: [new RequestedObservingTime()],
        schedulingRemarks: "",
        principalInvestigator: "",
        coInvestigators: "",
        isLinkedWork: false,
        nameOfStudents: "",
        roleOfStudents: "",
        scientificRationale: "",
        immediateObjective: "",
        telescopJustification: "",
        strategies: "",
        figures: [],
        timeJustification: "",
        previouslyAwardedTimes: [],
        previouslyAwardedTimesComments: "",
        publications: [],
        listOfTargets: [new Target()],
        listOfTargetsComments: "",
        errors: new ErrorList(),
        submitMessage: "",

    }

    setValue = event => {
        let targetName = event.target.name;
        let value = event.target.value;

        let newErrors = { ...this.state.errors };

        switch (targetName) {
            case "applicantName":
                newErrors.applicantName = textValidityCheck(value, valReqs.applicantName);
                this.setState({ applicantName: value, errors: newErrors });
                break;

            case "applicantEmail":
                newErrors.applicantEmail = textValidityCheck(value, valReqs.applicantEmail);
                this.setState({ applicantEmail: value, errors: newErrors });
                break;

            case "applicantAffiliation":
                newErrors.applicantAffiliation = textValidityCheck(value, valReqs.applicantAffiliation);
                this.setState({ applicantAffiliation: value, errors: newErrors });
                break;

            case "title":
                newErrors.title = textValidityCheck(value, valReqs.title);
                this.setState({ title: value, errors: newErrors });
                break;

            case "abstract":
                newErrors.abstract = textValidityCheck(value, valReqs.abstract);
                this.setState({ abstract: value, errors: newErrors });
                break;

            case "schedulingRemarks":
                newErrors.schedulingRemarks = textValidityCheck(value, valReqs.schedulingRemarks);
                this.setState({ schedulingRemarks: value, errors: newErrors });
                break;

            case "principalInvestigator":
                newErrors.principalInvestigator = textValidityCheck(value, valReqs.principalInvestigator);
                this.setState({ principalInvestigator: value, errors: newErrors });
                break;

            case "coInvestigators":
                newErrors.coInvestigators = textValidityCheck(value, valReqs.coInvestigators);
                this.setState({ coInvestigators: value, errors: newErrors });
                break;

            case "isLinkedWork":
                this.setState({ isLinkedWork: event.target.checked });
                break;

            case "nameOfStudents":
                newErrors.nameOfStudents = textValidityCheck(value, valReqs.nameOfStudents);
                this.setState({ nameOfStudents: value, errors: newErrors });
                break;

            case "roleOfStudents":
                newErrors.roleOfStudents = textValidityCheck(value, valReqs.roleOfStudents);
                this.setState({ roleOfStudents: value, errors: newErrors });
                break;

            case "scientificRationale":
                newErrors.scientificRationale = textValidityCheck(value, valReqs.scientificRationale);
                this.setState({ scientificRationale: value, errors: newErrors });
                break;

            case "immediateObjective":
                newErrors.immediateObjective = textValidityCheck(value, valReqs.immediateObjective);
                this.setState({ immediateObjective: value, errors: newErrors });
                break;

            case "telescopJustification":
                newErrors.telescopJustification = textValidityCheck(value, valReqs.telescopJustification);
                this.setState({ telescopJustification: value, errors: newErrors });
                break;

            case "strategies":
                newErrors.strategies = textValidityCheck(value, valReqs.strategies);
                this.setState({ strategies: value, errors: newErrors });
                break;

            case "timeJustification":
                newErrors.timeJustification = textValidityCheck(value, valReqs.timeJustification);
                this.setState({ timeJustification: value, errors: newErrors });
                break;

            case "previouslyAwardedTimesComments":
                newErrors.previouslyAwardedTimesComments = textValidityCheck(value, valReqs.previouslyAwardedTimesComments);
                this.setState({ previouslyAwardedTimesComments: value, errors: newErrors });
                break;

            case "listOfTargetsComments":
                newErrors.listOfTargetsComments = textValidityCheck(value, valReqs.listOfTargetsComments);
                this.setState({ listOfTargetsComments: value, errors: newErrors })
                break;

            default:
                break;

        }
    }

    setRequestedObservingTimes = event => {

        let targetName = event.target.name;
        let value = event.target.value;
        let targetIndex = event.target.getAttribute("index");

        let items = [...this.state.requestedObservingTimes];
        let item = { ...items[targetIndex] };

        if (targetName === "telescope") {
            item.telescope = value;

            // make sure is detector set on imaging when telescope is set to other than RCC
            if (item.telescope !== "RCC") {
                item.detector = "Imaging";
            }
            if (item.telescope === "80 cm") {
                item.obsMode = "Q";
            }
        } else if (targetName === "detector") {
            item.detector = value;
        } else if (targetName === "obsMode") {
            item.obsMode = value;
        } else if (targetName === "obsTimeMin") {
            item.obsTimeMin = value;
        } else if (targetName === "obsTimeMax") {
            item.obsTimeMax = value;
        }

        items[targetIndex] = item;

        this.setState({ requestedObservingTimes: items })
    };

    setFigures = async (event) => {

        let targetName = event.target.name;
        let value = event.target.value;
        let files = event.target.files;

        let targetIndex = event.target.getAttribute("index");

        let items = [...this.state.figures];
        let item = { ...items[targetIndex] };

        if (targetName === "file") {
            let imageFile = files[0];
            if (!imageFile) {
                item.error = "Please select image.";
                item.file = null;
                items[targetIndex] = item;

                this.setState({ figures: items });
                return false;
            }

            if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG|PNG|JPEG)$/)) {
                item.error = "Please select valid image. (jpg/png)"
                item.file = null;
                items[targetIndex] = item;

                this.setState({ figures: items });
                return false;
            }

            this.reader.onload = (e) => {
                const img = new Image();
                img.onload = () => {
                    item.error = "";
                    item.file = this.reader.result;
                    item.blob = URL.createObjectURL(event.target.files[0]);

                    if (event.target.files[0].size > maxImageSize) {
                        item.error = "File size should be less then 1 MB.";
                        item.file = null;
                        item.blob = null;
                        items[targetIndex] = item;
                        this.setState({ figures: items });

                        return false;
                    }

                    uploadImage(files[0]).then((result) => {
                        if (result.error) {
                            item.error = "Could not upload image.";
                            item.file = null;
                            item.blob = null;
                            items[targetIndex] = item;
                            this.setState({ figures: items });
                            return false;
                        }

                        item.file = result.name;
                        items[targetIndex] = item;
                        this.setState({ figures: items });
                    })



                };
                img.onerror = () => {
                    item.error = "Invalid image content.";
                    item.file = null;
                    item.blob = null;
                    items[targetIndex] = item;
                    this.setState({ figures: items });
                    return false;
                };
                img.src = e.target.result;
            };
            this.reader.readAsDataURL(imageFile);

        } else if (targetName === "caption") {
            item.caption = value;
            items[targetIndex] = item;

            this.setState({ figures: items });
        }

    };

    setPreviouslyAwardedTimes = event => {

        let targetName = event.target.name;
        let value = event.target.value;
        let targetIndex = event.target.getAttribute("index");

        let newPrevAwardedTimes = [...this.state.previouslyAwardedTimes];
        let newTime = { ...newPrevAwardedTimes[targetIndex] };

        if (targetIndex) {
            if (targetName === "period") {
                newTime.period = value;
            } else if (targetName === "telescope") {
                newTime.telescope = value;
            } else if (targetName === "nights") {
                newTime.nights = value;
            } else if (targetName === "clear") {
                newTime.clear = value;
            }

            newPrevAwardedTimes[targetIndex] = newTime;
        }

        this.setState({ previouslyAwardedTimes: newPrevAwardedTimes })
    };

    setPublications = event => {
        let value = event.target.value;
        let targetIndex = event.target.getAttribute("index");

        let items = [...this.state.publications];


        items[targetIndex] = value;


        this.setState({ publications: items })

    };

    setListOfTargets = event => {


        let targetName = event.target.name;
        let value = event.target.value;
        let targetIndex = event.target.getAttribute("index");

        let newListOfTargets = [...this.state.listOfTargets];
        let newTarget = { ...newListOfTargets[targetIndex] };

        if (targetName === "object") {
            newTarget.object = value;
        } else if (targetName === "ra") {
            newTarget.ra = value;
        } else if (targetName === "dec") {
            newTarget.dec = value;
        } else if (targetName === "telescope") {
            newTarget.telescope = value;
        } else if (targetName === "nights") {
            newTarget.nights = value;
        } else if (targetName === "vmag") {
            newTarget.vmag = value;
        } else if (targetName === "priority") {
            newTarget.priority = value;
        }

        newListOfTargets[targetIndex] = newTarget;

        this.setState({ listOfTargets: newListOfTargets })
    };

    addNewRequestedObservingTime = () => {
        let newReqObsTimes = [...this.state.requestedObservingTimes];

        if (newReqObsTimes.length < rowLimits.requestObservingTimes)
            newReqObsTimes.push(new RequestedObservingTime());

        this.setState({ requestedObservingTimes: newReqObsTimes });
    };

    removeRequestedObservingTime = event => {
        let targetIndex = event.target.getAttribute("index");

        let newReqObsTimes = [...this.state.requestedObservingTimes];

        newReqObsTimes.splice(targetIndex, 1);

        this.setState({ requestedObservingTimes: newReqObsTimes });
    };


    addFigure = () => {
        let newFigures = [...this.state.figures]

        if (newFigures.length < rowLimits.figures)
            newFigures.push(new Fig());

        this.setState({ figures: newFigures });
    };

    removeFigure = event => {
        let targetIndex = event.target.getAttribute("index");

        let newFigures = [...this.state.figures];

        newFigures.splice(targetIndex, 1);

        this.setState({ figures: newFigures });
    };

    addNewPreviouslyAwardedTime = () => {
        let newPrevAwardedTimes = [...this.state.previouslyAwardedTimes];

        if (newPrevAwardedTimes.length < rowLimits.previouslyAwardedTimes)
            newPrevAwardedTimes.push(new PreviouslyAwardedTime());

        this.setState({ previouslyAwardedTimes: newPrevAwardedTimes });
    }

    removePreviouslyAwardedTime = event => {
        let targetIndex = event.target.getAttribute("index");

        let newPrevAwardedTimes = [...this.state.previouslyAwardedTimes];

        newPrevAwardedTimes.splice(targetIndex, 1);

        this.setState({ previouslyAwardedTimes: newPrevAwardedTimes });

    }

    addNewPublication = () => {
        let newPublications = [...this.state.publications];

        if (newPublications.length < rowLimits.publications)
            newPublications.push("");
        this.setState({ publications: newPublications });
    }

    removePublication = event => {
        let targetIndex = event.target.getAttribute("index");

        let newPublications = [...this.state.publications];

        newPublications.splice(targetIndex, 1);

        this.setState({ publications: newPublications });

    }

    addNewTarget = () => {
        let newTargets = [...this.state.listOfTargets];

        if (newTargets.length < rowLimits.listOfTargets)
            newTargets.push(new Target());

        this.setState({ listOfTargets: newTargets });
    }

    removeTarget = event => {
        let targetIndex = event.target.getAttribute("index");

        let newTargets = [...this.state.listOfTargets];

        newTargets.splice(targetIndex, 1);

        this.setState({ listOfTargets: newTargets });

    }

    log = () => {
        // This is for dev purposes only.
        console.log("Nothing to log at this very moment.");

    }

    handleSubmit = event => {
        event.preventDefault();

        // Checking for errors on client side
        var containsError = this.checkErrors();

        if (containsError) {
            return;
        }

        let form = { ...this.state };

        // Remove unnecessary elements from the form
        delete form.errors;
        delete form.submitMessage;

        form.figures.forEach(fig => {
            delete fig.blob;
            delete fig.error;
        })

        // Get ReCAPTCH token
        const token = recaptchaRef.current.getValue();

        postForm(form, token)
            .then(response => {
                if (response.message) {
                    this.setState({ submitMessage: "The form was successfully submitted." });
                } else {
                    throw new Error(response.errors);
                }


            })
            .catch(error => {
                this.setState({ submitMessage: `Submission error: ${error}` });
            });
    };

    checkErrors = () => {
        let newErrors = { ...this.state.errors }

        // Force check validity
        let reqs = Object.keys(valReqs);
        reqs.forEach(req => {
            newErrors[req] = textValidityCheck(this.state[req], valReqs[req]);

        });

        this.setState({ errors: newErrors });

        let errorValues = Object.values(newErrors);

        for (let i = 0; i < errorValues.length; i++) {
            if (errorValues[i]) {
                this.setState({ submitMessage: "You have to fix all validation errors before submission." });

                return true;
            }
        }

        // Check image validity
        for (let i = 0; i < this.state.figures.length; i++) {
            if (this.state.figures[i].error) {
                console.log(this.state.figures[i].error);
                this.setState({ submitMessage: "You have to fix all validation errors before submission." });

                return true;
            }
        }

        this.setState({ submitMessage: "" });

        return false;
    }

    render() {

        const {
            applicantName,
            applicantEmail,
            applicantAffiliation,
            title,
            abstract,
            requestedObservingTimes,
            schedulingRemarks,
            principalInvestigator,
            coInvestigators,
            isLinkedWork,
            nameOfStudents,
            roleOfStudents,
            scientificRationale,
            immediateObjective,
            telescopJustification,
            strategies,
            figures,
            timeJustification,
            previouslyAwardedTimes,
            previouslyAwardedTimesComments,
            publications,
            listOfTargets,
            listOfTargetsComments,
            errors,
            submitMessage
        } = this.state;


        return (
            <>
                <Container>
                    <Row>
                        <Col md={{ span: 8, offset: 2 }}>
                            <Jumbotron>
                                <h2>Piszkéstetői Obszervatórium</h2>
                                <h3>Application for observing time</h3>
                                <hr />

                                <SubmitMessage message={submitMessage} />

                                <Form onSubmit={this.handleSubmit}>
                                    <Form.Group>
                                        <Form.Label>Name<RequiredMark /></Form.Label>
                                        <Form.Control
                                            type="input"
                                            value={applicantName}
                                            placeholder="Name"
                                            name="applicantName"
                                            onChange={this.setValue}
                                        />
                                        <ErrorMessage message={errors.applicantName} />
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Email<RequiredMark /></Form.Label>
                                        <Form.Control
                                            type="email"
                                            value={applicantEmail}
                                            placeholder="Email"
                                            name="applicantEmail"
                                            onChange={this.setValue}
                                        />
                                        <ErrorMessage message={errors.applicantEmail} />
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Affiliation<RequiredMark /></Form.Label>
                                        <Form.Control
                                            type="input"
                                            value={applicantAffiliation}
                                            placeholder="Affiliation"
                                            name="applicantAffiliation"
                                            onChange={this.setValue}
                                        />
                                        <ErrorMessage message={errors.applicantAffiliation} />
                                    </Form.Group>
                                    <hr />
                                    <Form.Group>
                                        <Form.Label><h4>1. Title<RequiredMark /></h4></Form.Label>
                                        <Form.Control
                                            type="input"
                                            value={title}
                                            placeholder="Title of your proposal"
                                            name="title"
                                            onChange={this.setValue}
                                        />
                                        <ErrorMessage message={errors.title} />
                                    </Form.Group>

                                    <hr />

                                    <Form.Group>
                                        <Form.Label><h4>2. Abstract<RequiredMark /></h4></Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows="10"
                                            value={abstract}
                                            placeholder="Include your abstract here."
                                            name="abstract"
                                            onChange={this.setValue}
                                        />
                                        <ErrorMessage message={errors.abstract} />
                                    </Form.Group>

                                    <hr />

                                    <h4>3. Requested Observing Time<RequiredMark /></h4>
                                    <InfoText content="You can specify the units in the Time (min) and Time (max) columns." />
                                    <div className="table-observingtimes">
                                        <p></p>
                                        <p>Telescope</p>
                                        <p>Detector</p>
                                        <p>Obs. <br />Mode</p>
                                        <p>Time <br />(min)</p>
                                        <p>Time <br />(max)</p>
                                        <p></p>
                                    </div>

                                    {requestedObservingTimes.map((reqObsTime, i) =>
                                        <div key={i.toString()} className="table-observingtimes">
                                            <p className="row-centered">{i + 1}.</p>
                                            <Form.Group>
                                                <Form.Control
                                                    as="select"
                                                    value={reqObsTime.telescope}
                                                    name="telescope"
                                                    index={i}
                                                    onChange={this.setRequestedObservingTimes}
                                                >
                                                    <option>RCC</option>
                                                    <option>80 cm</option>
                                                    <option>40 cm</option>
                                                </Form.Control>
                                            </Form.Group>

                                            <Form.Group>
                                                <Form.Control
                                                    as="select"
                                                    value={reqObsTime.detector}
                                                    name="detector"
                                                    index={i}
                                                    onChange={this.setRequestedObservingTimes}
                                                >
                                                    <option>Imaging</option>
                                                    {reqObsTime.telescope === "RCC" ? <option>Spectroscopy</option> : null}
                                                    {reqObsTime.telescope === "RCC" ? <option>Imaging + spectroscopy</option> : null}
                                                    {reqObsTime.telescope === "RCC" ? <option>EMCCD</option> : null}
                                                </Form.Control>
                                            </Form.Group>

                                            <Form.Group>
                                                <Form.Control
                                                    as="select"
                                                    value={reqObsTime.obsMode}
                                                    name="obsMode"
                                                    index={i}
                                                    onChange={this.setRequestedObservingTimes}
                                                >
                                                    {reqObsTime.telescope === "80 cm" ? null : <option>C</option>}
                                                    <option>Q</option>
                                                </Form.Control>
                                            </Form.Group>

                                            <Form.Group>
                                                <Form.Control
                                                    as="input"
                                                    value={reqObsTime.obsTimeMin}
                                                    name="obsTimeMin"
                                                    index={i}
                                                    onChange={this.setRequestedObservingTimes}
                                                />
                                            </Form.Group>

                                            <Form.Group>
                                                <Form.Control
                                                    as="input"
                                                    value={reqObsTime.obsTimeMax}
                                                    name="obsTimeMax"
                                                    index={i}
                                                    onChange={this.setRequestedObservingTimes}
                                                />
                                            </Form.Group>

                                            {requestedObservingTimes.length > 1 ?
                                                < Button
                                                    className="row-button"
                                                    variant="danger"
                                                    index={i}
                                                    onClick={this.removeRequestedObservingTime}
                                                >
                                                    &#10008;
                                                </Button>
                                                : null}
                                        </div>
                                    )}


                                    <Button
                                        variant="primary"
                                        onClick={this.addNewRequestedObservingTime}
                                        disabled={requestedObservingTimes.length >= rowLimits.requestObservingTimes}
                                    >
                                        Add
                                    </Button>

                                    <hr />

                                    <Form.Group>
                                        <Form.Label><h4>4. Scheduling Remarks</h4></Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows="10"
                                            value={schedulingRemarks}
                                            placeholder="A place for scheduling remarks."
                                            name="schedulingRemarks"
                                            onChange={this.setValue}
                                        />
                                        <ErrorMessage message={errors.schedulingRemarks} />
                                    </Form.Group>

                                    <hr />

                                    <Form.Group>
                                        <h4>5. Investigator(s)</h4>
                                        <Form.Label>Principal Investigator<RequiredMark /></Form.Label>
                                        <Form.Control
                                            as="input"
                                            value={principalInvestigator}
                                            name="principalInvestigator"
                                            placeholder="Name"
                                            onChange={this.setValue}
                                        />
                                        <ErrorMessage message={errors.principalInvestigator} />
                                        <br />
                                        <Form.Label>CoI(s)</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows="3"
                                            value={coInvestigators}
                                            name="coInvestigators"
                                            placeholder="Name(s)"
                                            onChange={this.setValue}
                                        />
                                        <ErrorMessage message={errors.coInvestigators} />
                                    </Form.Group>

                                    <hr />

                                    <Form.Group>

                                        <Form.Label><h4>6. Is this proposal linked to a Practicum/Masters/PhD thesis preparation?</h4></Form.Label>
                                        <Form.Check
                                            type="checkbox"
                                            label="Yes"
                                            checked={isLinkedWork}
                                            name="isLinkedWork"
                                            placeholder="Name"
                                            onChange={this.setValue}
                                        />
                                        <br />
                                        {isLinkedWork ?
                                            <>
                                                <Form.Label>Name of Student(s)</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows="3"
                                                    value={nameOfStudents}
                                                    name="nameOfStudents"
                                                    placeholder="Name(s)"
                                                    onChange={this.setValue}
                                                />
                                                <ErrorMessage message={errors.nameOfStudents} />
                                                <br />
                                                <Form.Label>Role of Student(s)</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows="3"
                                                    value={roleOfStudents}
                                                    name="roleOfStudents"
                                                    placeholder="Role"
                                                    onChange={this.setValue}
                                                />
                                                <ErrorMessage message={errors.roleOfStudents} />
                                            </>
                                            : null}

                                    </Form.Group>

                                    <hr />

                                    <Form.Group>
                                        <h4>7. Description of the proposed programme</h4>

                                        <Form.Label>A) Scientific Rationale<RequiredMark /></Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows="5"
                                            value={scientificRationale}
                                            name="scientificRationale"
                                            placeholder="Describe scientific rationale..."
                                            onChange={this.setValue}
                                        />
                                        <ErrorMessage message={errors.scientificRationale} />

                                        <Form.Label>B) Immediate Objective<RequiredMark /></Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows="5"
                                            value={immediateObjective}
                                            name="immediateObjective"
                                            placeholder="Describe immediate objective..."
                                            onChange={this.setValue}
                                        />
                                        <ErrorMessage message={errors.immediateObjective} />

                                        <Form.Label>C) Telescope Justification<RequiredMark /></Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows="5"
                                            value={telescopJustification}
                                            name="telescopJustification"
                                            placeholder="Describe telescope justification..."
                                            onChange={this.setValue}
                                        />
                                        <ErrorMessage message={errors.telescopJustification} />

                                        <Form.Label>D) Strategy for Data Reduction Analysis
                                            and Publication<RequiredMark /></Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows="5"
                                            value={strategies}
                                            name="strategies"
                                            placeholder="Describe strategy..."
                                            onChange={this.setValue}
                                        />
                                        <ErrorMessage message={errors.strategies} />
                                    </Form.Group>

                                    <hr />

                                    <h4>8. Figure(s)</h4>
                                    {figures.map((figure, i) =>
                                        <Form.Group key={i.toString()}>
                                            <div className="upload-image-select">
                                                <Form.Control
                                                    type="file"
                                                    accept="image/jpeg, image/png"
                                                    name="file"
                                                    onChange={this.setFigures}
                                                    index={i}
                                                />

                                                <p >
                                                    {figures.length >= 1 ?
                                                        < Button
                                                            variant="danger"
                                                            index={i}
                                                            onClick={this.removeFigure}
                                                        >
                                                            &#10008;
                                                        </Button>
                                                        : null}
                                                </p>
                                            </div>

                                            <div>
                                                {figure.blob ?
                                                    <img
                                                        className="upload-image"
                                                        src={figure.blob} alt="preview of upload" />
                                                    : null}
                                            </div>
                                            <ErrorMessage message={figure.error} />

                                            <Form.Control
                                                type="input"
                                                value={figure.caption}
                                                placeholder={`Fig. ${i + 1}  Add caption to your figure`}
                                                name="caption"
                                                onChange={this.setFigures}
                                                index={i}
                                            />
                                        </Form.Group>
                                    )}

                                    <Button
                                        variant="primary"
                                        onClick={this.addFigure}
                                        disabled={figures.length >= rowLimits.figures}
                                    >
                                        Add Figure
                                    </Button>

                                    <hr />


                                    <Form.Group>
                                        <Form.Label><h4>9. Justification of requrested observing time<RequiredMark /></h4></Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows="10"
                                            value={timeJustification}
                                            placeholder="Especially the priority categories of the targets on the 80 cm telescope."
                                            name="timeJustification"
                                            onChange={this.setValue}
                                        />
                                        <ErrorMessage message={errors.timeJustification} />
                                    </Form.Group>



                                    <hr />

                                    <h4>10. Time awarded in the last two years:</h4>
                                    <InfoText content="You can specify the units in the Nights/Hours and Clear columns." />
                                    {previouslyAwardedTimes.length >= 1 ?
                                        <div className="table-awardedtimes table-header">
                                            <p></p>
                                            <Form.Label>Period</Form.Label>
                                            <Form.Label>Telescope</Form.Label>
                                            <Form.Label>Nights / Hours</Form.Label>
                                            <Form.Label>Clear</Form.Label>
                                            <p></p>

                                        </div>
                                        : null}
                                    {previouslyAwardedTimes.map((awardedTime, i) =>
                                        <div key={i.toString()} className="table-awardedtimes">

                                            <p className="row-centered">{i + 1}.</p>
                                            <Form.Group>
                                                <Form.Control
                                                    as="input"
                                                    value={awardedTime.period}
                                                    name="period"
                                                    index={i}
                                                    onChange={this.setPreviouslyAwardedTimes}
                                                />
                                            </Form.Group>

                                            <Form.Group>
                                                <Form.Control
                                                    as="select"
                                                    value={awardedTime.telescope}
                                                    name="telescope"
                                                    index={i}
                                                    onChange={this.setPreviouslyAwardedTimes}
                                                >
                                                    <option>RCC</option>
                                                    <option>80 cm</option>
                                                    <option>40 cm</option>
                                                    <option>Schmidt</option>
                                                </Form.Control>
                                            </Form.Group>

                                            <Form.Group>
                                                <Form.Control
                                                    as="input"
                                                    value={awardedTime.nights}
                                                    name="nights"
                                                    index={i}
                                                    onChange={this.setPreviouslyAwardedTimes}
                                                />
                                            </Form.Group>

                                            <Form.Group>
                                                <Form.Control
                                                    as="input"
                                                    value={awardedTime.clear}
                                                    name="clear"
                                                    index={i}
                                                    onChange={this.setPreviouslyAwardedTimes}
                                                />
                                            </Form.Group>

                                            < Button
                                                className="row-button"
                                                variant="danger"
                                                index={i}
                                                onClick={this.removePreviouslyAwardedTime}
                                            >
                                                &#10008;
                                            </Button>
                                        </div>
                                    )}

                                    <Button
                                        variant="primary"
                                        onClick={this.addNewPreviouslyAwardedTime}
                                        disabled={previouslyAwardedTimes.length >= rowLimits.previouslyAwardedTimes}
                                    >
                                        Add
                                    </Button>

                                    <Form.Group >
                                        <Form.Label>
                                            <br />Comments:
                                        </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows="3"
                                            value={previouslyAwardedTimesComments}
                                            name="previouslyAwardedTimesComments"
                                            onChange={this.setValue}
                                        />
                                        <ErrorMessage message={errors.previouslyAwardedTimesComments} />
                                    </Form.Group>

                                    <hr />

                                    <h4>11. Applicants' publications related to the subject of this application
                                        or to earlier Piszkéstető observations during the last 5 years:
                                    </h4>
                                    {publications.map((publication, i) =>
                                        <div key={i.toString()} className="table-publications">
                                            <p className="row-centered">{i + 1}.</p>
                                            <Form.Group>
                                                <Form.Control
                                                    as="input"
                                                    value={publication}
                                                    index={i}
                                                    onChange={this.setPublications}
                                                />
                                            </Form.Group>

                                            < Button
                                                className="row-button"
                                                variant="danger"
                                                index={i}
                                                onClick={this.removePublication}
                                            >
                                                &#10008;
                                            </Button>
                                        </div>
                                    )}

                                    <Button
                                        variant="primary"
                                        onClick={this.addNewPublication}
                                        disabled={publications.length >= rowLimits.publications}
                                    >
                                        Add
                                    </Button>


                                    <hr />

                                    <h4>12. List of targets proposed in this programme<RequiredMark /></h4>
                                    <InfoText content="You can specify the units in the Nights/Hours column." />
                                    <div className="table-targets table-header">
                                        <p></p>
                                        <p>Object</p>
                                        <p>RA</p>
                                        <p>Dec</p>
                                        <p>Telescope</p>
                                        <p>Nights / Hours </p>
                                        <p>vmag</p>
                                        <p>Priority</p>
                                    </div>
                                    {listOfTargets.map((target, i) =>
                                        <div key={i.toString()} className="table-targets">
                                            <p className="row-centered">{i + 1}.</p>
                                            <Form.Group>
                                                <Form.Control
                                                    as="input"
                                                    name="object"
                                                    value={target.object}
                                                    index={i}
                                                    onChange={this.setListOfTargets}
                                                />
                                            </Form.Group>

                                            <Form.Group>
                                                <Form.Control
                                                    as="input"
                                                    name="ra"
                                                    value={target.ra}
                                                    index={i}
                                                    onChange={this.setListOfTargets}
                                                />
                                            </Form.Group>

                                            <Form.Group>
                                                <Form.Control
                                                    as="input"
                                                    name="dec"
                                                    value={target.dec}
                                                    index={i}
                                                    onChange={this.setListOfTargets}
                                                />
                                            </Form.Group>

                                            <Form.Group>
                                                <Form.Control
                                                    as="select"
                                                    name="telescope"
                                                    value={target.telescope}
                                                    index={i}
                                                    onChange={this.setListOfTargets}
                                                >
                                                    <option>RCC</option>
                                                    <option>80 cm</option>
                                                    <option>40 cm</option>
                                                </Form.Control>
                                            </Form.Group>

                                            <Form.Group>
                                                <Form.Control
                                                    as="input"
                                                    name="nights"
                                                    value={target.nights}
                                                    index={i}
                                                    onChange={this.setListOfTargets}
                                                />
                                            </Form.Group>

                                            <Form.Group>
                                                <Form.Control
                                                    as="input"
                                                    name="vmag"
                                                    value={target.vmag}
                                                    index={i}
                                                    onChange={this.setListOfTargets}
                                                />
                                            </Form.Group>

                                            <Form.Group>
                                                <Form.Control
                                                    as="select"
                                                    name="priority"
                                                    value={target.priority}
                                                    index={i}
                                                    onChange={this.setListOfTargets}
                                                    >
                                                        <option>0</option>
                                                        <option>1</option>
                                                        <option>2</option>
                                                    </Form.Control>
                                            </Form.Group>

                                            {listOfTargets.length > 1 ?
                                                <Button
                                                    className="row-button"
                                                    variant="danger"
                                                    index={i}
                                                    onClick={this.removeTarget}
                                                >
                                                    &#10008;
                                                </Button>
                                                : null}
                                        </div>
                                    )}
                                    <Button
                                        variant="primary"
                                        onClick={this.addNewTarget}
                                        disabled={listOfTargets.length >= rowLimits.listOfTargets}
                                    >
                                        Add
                                    </Button>

                                    <Form.Group >
                                        <Form.Label>
                                            <br />Comments:
                                        </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows="3"
                                            value={listOfTargetsComments}
                                            placeholder="Observing windows, observing cadence, etc."
                                            name="listOfTargetsComments"
                                            onChange={this.setValue}
                                        />
                                        <ErrorMessage message={errors.listOfTargetsComments} />
                                    </Form.Group>


                                    <hr />

                                    <ReCAPTCHA
                                        sitekey={RecaptchaPublicKey}
                                        ref={recaptchaRef} />
                                    <br />

                                    <SubmitMessage message={submitMessage} />
                                    <br />
                                    <Button type="submit">Submit</Button>
                                </Form>


                            </Jumbotron>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default ApplicationFormContent;