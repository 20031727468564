import React from 'react';

class SubmitMessage extends React.Component {
    render() {
        return (
            <div>
                <span className="submit-message">{this.props.message}</span>
            </div>
        );
    }
}

export default SubmitMessage;