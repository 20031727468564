import React from "react";
import { Route, Switch } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';

import { ApplicationForm, ApplicationsList, ApplicationManagement, FormView } from "./views";
import { Loading } from "./components";
import ProtectedRoute from "./auth/protectedRoute";

import './App.css';
import ArchivedList from "./views/archivedList";

const App = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <Loading />
  }


  return (
    <Switch>
      <Route path="/" exact component={ApplicationForm} />

      <Route path="/management" exact component={ApplicationManagement} />
      <ProtectedRoute path="/management/applications" component={ApplicationsList} />
      <ProtectedRoute path="/management/form" component={FormView} />
      <ProtectedRoute path="/management/archive" component={ArchivedList} />
    </Switch>
  );
}


export default App;