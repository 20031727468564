import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { NavBar } from '../components';
import LoginBanner from '../components/loginBanner';
import ManagementSelector from '../components/managementSelector';

const ApplicationForm = () => {
    const { isAuthenticated } = useAuth0();

    return (
        <>
            <NavBar navContent="Management"/>


            {isAuthenticated ? <ManagementSelector /> : <LoginBanner />}

        </>
    );
}

export default ApplicationForm;