const charLimits = {
    applicantName: 35,
    applicantEmail: 35,
    applicantAffiliation: 100,
    title: 100,
    abstract: 700,
    schedulingRemarks: 500,
    principalInvestigator: 35,
    coInvestigators: 200,
    nameOfStudents: 200,
    roleOfStudents: 500,
    scientificRationale: 2000,
    immediateObjective: 2000,
    telescopJustification: 1000,
    strategies: 2000,
    timeJustification: 2000,
    previouslyAwardedTimesComments: 500,
} 

const rowLimits = {
    requestObservingTimes: 10,
    figures: 5,
    previouslyAwardedTimes: 8,
    publications: 15,
    listOfTargets: 100,
}

const maxImageSize = 1024*1024; // 1 MB

const validityRequirements = {
    applicantName: {charLimit: 35, isMandatory: true},
    applicantEmail: {charLimit: 35, isMandatory: true},
    applicantAffiliation: {charLimit: 100, isMandatory: true},
    title: {charLimit: 100, isMandatory: true},
    abstract: {charLimit: 700, isMandatory: true},
    schedulingRemarks: {charLimit: 500, isMandatory: false},
    principalInvestigator: {charLimit: 35, isMandatory: true},
    coInvestigators: {charLimit: 200, isMandatory: false},
    nameOfStudents: {charLimit: 200, isMandatory: false},
    roleOfStudents: {charLimit: 500, isMandatory: false},
    scientificRationale: {charLimit: 2000, isMandatory: true},
    immediateObjective: {charLimit: 2000, isMandatory: true},
    telescopJustification: {charLimit: 1000, isMandatory: true},
    strategies: {charLimit: 2000, isMandatory: true},
    timeJustification: {charLimit: 2000, isMandatory: true},
    previouslyAwardedTimesComments: {charLimit: 500, isMandatory: false},
    listOfTargetsComments: {charLimit: 500, isMandatory: false},
}

const validMimeTypes = {
    jpg: 'image/jpeg',
    png: 'image/png',
    gif: 'image/gif'
};


module.exports = { charLimits, rowLimits, maxImageSize, validityRequirements, validMimeTypes };