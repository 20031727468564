import React from "react";
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArchive, faList, faWrench } from '@fortawesome/free-solid-svg-icons'

const ManagementSelector = () => {
    return (
        <Container>
            <div className="cards">
                <Link className="card-link" to="/management/applications">
                    <div className="card-selector">
                        <h1>
                            <FontAwesomeIcon icon={faList} />
                        </h1>
                        <h2>Pályázatok</h2>
                    </div>
                </Link>

                
                <Link className="card-link" to="/management/archive">
                    <div className="card-selector">
                        <h1>
                            <FontAwesomeIcon icon={faArchive} />
                        </h1>
                        <h2>Archív</h2>
                    </div>
                </Link>

                <div className="card-selector">
                    <h1>
                        <FontAwesomeIcon icon={faWrench} />
                    </h1>
                    <h2>Fejlesztés alatt</h2>
                </div>
            </div>
        </Container>
    )
}

export default ManagementSelector