import React from "react";
import { Container, Navbar, Button } from 'react-bootstrap';
import AuthNav from "./authNav";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

const NavBar = (props) => {
  return (

    <Navbar bg="dark" variant="dark" sticky="top">
      <Container className="navbar-text">
        <div className="navbar-container">
          <div className="navbar-text-title">{props.navContent}</div>
          <div className="navbar-double-button">
            <div>
              {
                props.navBack &&
                <Button variant="outline-info" href={props.navBack}>
                  <FontAwesomeIcon icon={faArrowLeft} className="teal"></FontAwesomeIcon>
                </Button>
              }
            </div>
            <AuthNav />
          </div>
        </div>
      </Container>
    </Navbar>
  );
};

export default NavBar;