class RequestedObservingTime {
    constructor() {
        this.telescope = "RCC";
        this.detector = "Imaging";
        this.obsMode = "C";
        this.obsTimeMin = 0;
        this.obsTimeMax = 0;

    }
}

class PreviouslyAwardedTime {
    constructor() {
        this.period = "";
        this.telescope = "RCC";
        this.nights = 0;
        this.clear = 0;

    }
}

class Fig {
    constructor() {
        this.file = "";
        this.caption = "";
        this.blob = null;
        this.error = "";
    }
}

class Target {
    constructor() {
        this.object = "";
        this.ra = "";
        this.dec = "";
        this.telescope = "RCC";
        this.nights = 0;
        this.vmag = 0;
        this.priority = 0;
    }
}

class ErrorList {
    constructor() {
        this.applicantName = "";
        this.applicantEmail = "";
        this.applicantAffiliation = "";
        this.title = "";
        this.abstract = "";
        this.requestedObservingTimes = "";
        this.schedulingRemarks = "";
        this.principalInvestigator = "";
        this.coInvestigators = "";
        this.nameOfStudents = "";
        this.roleOfStudents = "";
        this.scientificRationale = "";
        this.immediateObjective = "";
        this.telescopJustification = "";
        this.strategies = "";
        this.figures = "";
        this.timeJustification = "";
        this.previouslyAwardedTimesComments = "";
        this.listOfTargetsComments = "";
    }
}

export { RequestedObservingTime, PreviouslyAwardedTime, Fig, Target, ErrorList };