import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Container, Jumbotron, Row, Col, Navbar } from 'react-bootstrap';
import { ApplicantBlock, SingleBlock, InvestigatorsBlock, LinkedWorkBlock, ProgrammeBlock, FiguresBlock, PrevAwardedTimesBlock, TargetsTableBlock } from '../components/form/formViewBlocks';
import { ReqObsTimeTable, PublicationTable } from '../components/form/formViewTables';
import { getForm } from '../services/formServices'
import { NavBar } from '../components';

const FormView = () => {
    const [form, setForm] = useState([]);
    const [isArchived, setIsArchived] = useState([]);
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {

        const id = new URLSearchParams(window.location.search).get("id");
        const isArchived = new URLSearchParams(window.location.search).get("isArchived");

        getAccessTokenSilently().then(token => getForm(id, isArchived, token)).then((result) => {
            setForm(result.form);
            setIsArchived(isArchived);
        });
    }, [getAccessTokenSilently])

    return (
        <>

            <NavBar navContent={
                <Navbar.Text>
                    <ApplicantBlock content={{
                        "applicantName": form.applicantName,
                        "applicantAffiliation": form.applicantAffiliation,
                        "applicantEmail": form.applicantEmail
                    }} />
                </Navbar.Text>
            } navBack={`/management/${isArchived === 'true' ? 'archive': 'applications'}`}></NavBar>

            <Container>

                <Row>
                    <Col md={{ span: 8, offset: 2 }}>
                        <Jumbotron>

                            <SingleBlock title="1. Title" content={form.title} />

                            <SingleBlock title="2. Abstract" content={form.abstract} />

                            <ReqObsTimeTable content={form.requestedObservingTimes} />

                            <SingleBlock title="4. Scheduling Remarks" content={form.schedulingRemarks} />

                            <InvestigatorsBlock content={{
                                "principalInvestigator": form.principalInvestigator,
                                "coInvestigators": form.coInvestigators
                            }} />

                            <LinkedWorkBlock content={{
                                "isLinkedWork": form.isLinkedWork,
                                "nameOfStudents": form.nameOfStudents,
                                "roleOfStudents": form.roleOfStudents
                            }} />

                            <ProgrammeBlock content={{
                                "scientificRationale": form.scientificRationale,
                                "immediateObjective": form.immediateObjective,
                                "telescopJustification": form.telescopJustification,
                                "strategies": form.strategies
                            }} />

                            <FiguresBlock content={form.figures} />

                            <SingleBlock title="9. Justification of requested observing time" content={form.timeJustification} />

                            <PrevAwardedTimesBlock content={{
                                "times": form.previouslyAwardedTimes,
                                "comments": form.previouslyAwardedTimesComments
                            }} />

                            <PublicationTable content={form.publications} />

                            <TargetsTableBlock content={{
                                "targets": form.listOfTargets,
                                "comments": form.listOfTargetsComments
                            }} />
                        </Jumbotron>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default FormView;