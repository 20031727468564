import React from 'react';

import { ApplicationFormContent } from "../components";

class ApplicationForm extends React.Component {
    render() {

        return (
            <>
                <ApplicationFormContent />
            </>
        );
    }
}

export default ApplicationForm;