// /**
//  * LengthCheck desctiption
//  * @param {String} text
//  * @param {Number} maxValue
//  * @param {Boolean} isRequired
//  * @returns {String}
//  */

// function lengthCheck(text, maxValue=10000, isRequired=false)
// {
//     if (isRequired && text.length === 0)
//     {
//         return "Required."
//     }
//     else if (maxValue < text.length)
//     {
//         return `Length should be less than ${maxValue} characters.`;
        
//     }
    
//     return "";
// }

function textValidityCheck(value, reqs) {

    if (reqs.isMandatory && value.length === 0 )
    {
        return "Required."
    }
    else if (value.length >= reqs.charLimit) {
        return `Length should be less than ${reqs.charLimit} characters.`;
    }

    return "";
}


// async function imageValidityCheck(imageFile) {
//     if(!imageFile.name.match(/\.(jpg|jpeg|png)$/))
//     {
//         return "Last selected file is not valid.";
//     }
//     let isImage = await isImageContent(imageFile);
//     console.log(isImage);

//     return isImage ? "" : "Last selected image content is not valid.";
// }


 export {textValidityCheck};