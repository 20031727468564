import React from "react";
import { Table } from 'react-bootstrap';

class ReqObsTimeTable extends React.Component {
    render() {
        return (
            <div>
                <h4>3. Requested Observing Time(s)</h4>
                <Table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Telescope</th>
                            <th>Detector</th>
                            <th>Obs. Mode</th>
                            <th>Time (min)</th>
                            <th>Time (max)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.content && (
                            this.props.content.map((time, i) =>
                                <tr key={i}>
                                    <td>{i + 1}.</td>
                                    <td>{time.telescope}</td>
                                    <td>{time.detector}</td>
                                    <td>{time.obsMode}</td>
                                    <td>{time.obsTimeMin}</td>
                                    <td>{time.obsTimeMax}</td>
                                </tr>
                            ))
                        }
                    </tbody>

                </Table>
            </div>
        )
    }
}

class AwardedTimesTable extends React.Component {
    render() {
        return (
            <Table>
                <thead>
                    <tr>
                        <th></th>
                        <th>Period</th>
                        <th>Telescope</th>
                        <th>Nights</th>
                        <th>Clear</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.content && (
                        this.props.content.map((time, i) =>
                            <tr key={i}>
                                <td>{i + 1}.</td>
                                <td>{time.period}</td>
                                <td>{time.telescope}</td>
                                <td>{time.nights}</td>
                                <td>{time.clear}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        )
    }
}

class PublicationTable extends React.Component {
    render() {
        return (
            <div>
                <h4>11. Applicants's publications related to the subject of this application or
                    to earlier Piszkéstető observations during the last 5 years</h4>
                <Table>
                    <tbody>
                        {this.props.content && (
                            this.props.content.map((item, i) =>
                                <tr key={i}>
                                    <td>{i + 1}.</td>
                                    <td>{item}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
                <hr />
            </div>
        )
    }
}

class TargetsTable extends React.Component {
    render() {
        return (
            <div>
                <Table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Object</th>
                            <th>RA</th>
                            <th>Dec</th>
                            <th>Telescope</th>
                            <th>Nights</th>
                            <th>vmag</th>
                            <th>priority</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.content && (
                            this.props.content.map((target, i) =>
                                <tr key={i}>
                                    <td>{i + 1}.</td>
                                    <td>{target.object}</td>
                                    <td>{target.ra}</td>
                                    <td>{target.dec}</td>
                                    <td>{target.telescope}</td>
                                    <td>{target.nights}</td>
                                    <td>{target.vmag}</td>
                                    <td>{target.priority}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>
        )
    }
}

export { ReqObsTimeTable, AwardedTimesTable, PublicationTable, TargetsTable }