import React from 'react';
import { AwardedTimesTable, TargetsTable } from './formViewTables';

class SingleBlock extends React.Component {
    render() {
        return (
            <div>
                <h4>{this.props.title}</h4>
                <p>{this.props.content}</p>
                <hr />
            </div>
        )
    }
}

class InvestigatorsBlock extends React.Component {
    render() {
        return (
            <div>
                <h4>5. Investigator(s)</h4>
                <h6>Principal Investigator</h6>
                <p>{this.props.content.principalInvestigator}</p>

                <h6>CoI(s)</h6>
                <p>{this.props.content.coInvestigators}</p>
                <hr />


            </div>

        )
    }
}

class LinkedWorkBlock extends React.Component {
    render() {
        return (
            <div>
                <h4>6. Is this proposal linked to a Practicum/Masters/PhD thesis preparation?</h4>
                {this.props.content.isLinkedWork ?
                    <>
                        <p>Yes</p>
                        <h6>Name of student(s)</h6>
                        <p>{this.props.content.nameOfStudents}</p>
                        <h6>Role of student(s)</h6>
                        <p>{this.props.content.roleOfStudents}</p>

                    </> :
                    <p>No</p>}
                <hr />
            </div>
        )
    }
}

class ProgrammeBlock extends React.Component {
    render() {
        return (
            <div>
                <h4>7. Description of the proposed programme</h4>
                <h6>A) Scientific Rationale</h6>
                <p>{this.props.content.scientificRationale}</p>
                <h6>B) Immediate Objective</h6>
                <p>{this.props.content.immediateObjective}</p>
                <h6>C) Telescope Justification</h6>
                <p>{this.props.content.telescopJustification}</p>
                <h6>D) Strategy for Data Reduction Analysis and Publication</h6>
                <p>{this.props.content.strategies}</p>
                <hr />
            </div>
        );
    }
}

class FiguresBlock extends React.Component {
    render() {
        return (
            <div>
                <h4>8. Figure(s)</h4>
                {this.props.content && (this.props.content.map((item, i) =>
                    <div key={i}>
                        <img className="upload-image" src={`/api/image?id=${item.file}`} alt={item.caption} />
                        <p>{item.caption}</p>
                    </div>
                ))
                }
                <hr />
            </div>
        )
    }
}

class PrevAwardedTimesBlock extends React.Component {
    render() {
        return (
            <div>
                <h4>10. Time awarded in the last two years</h4>
                {this.props.content.times && this.props.content.times.length >= 1 ?
                    <AwardedTimesTable content={this.props.content.times} />
                    : <p>-</p>}
                <h6>Comments:</h6>
                <p>{this.props.content.comments}</p>
            </div>
        )
    }
}

class TargetsTableBlock extends React.Component {
    render() {
        return (
            <div>
                <h4>12. List of targets proposed in this programme</h4>
                {this.props.content.targets && this.props.content.targets.length >= 1 ? 
                <TargetsTable content={this.props.content.targets} />
                    : <p>-</p>}
                <h6>Comments:</h6>
                <p>{this.props.content.comments}</p>
            </div>
        )
    }
}


class ApplicantBlock extends React.Component {
    render() {
        return (
            <div className="navbar-text">
                <h3 className="navbar-text-title">{this.props.content.applicantName}</h3>
                <p><em>{this.props.content.applicantAffiliation}</em></p>
                <p>{this.props.content.applicantEmail}</p>
            </div>
        )
    }
}


export { SingleBlock, InvestigatorsBlock, LinkedWorkBlock, ProgrammeBlock, FiguresBlock, PrevAwardedTimesBlock, TargetsTableBlock, ApplicantBlock };